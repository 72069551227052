@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
}

form {
  width: 100%;
}

.scrollbar-hide {
  &::-webkit-scrollbar {
    display: none;
  }
}
